import * as React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { OuterLink } from '@shared/ui/GenericLink';
import clsx from 'clsx';

interface Image {
  src: string;
  content: any;
  className: string;
}

interface PartnerItemProps {
  name: string;
  image: Image;
  url: string;
  withBorder?: boolean;
  innerRef?: React.RefObject<HTMLDivElement> | null;
}

export const PartnerItem: React.FC<PartnerItemProps> = ({ name, image, url, withBorder = true, innerRef }) => {
  const imageOutputClass = React.useMemo(() => {
    return clsx(
      'group-hover:scale-110 select-none pointer-events-none transition-transform object-contain',
      image.className,
    );
  }, [image.className]);

  return (
    <div ref={innerRef} className="group select-none flex justify-center">
      <OuterLink
        to={url}
        className={clsx(
          'w-[15.5rem] min-h-[7.5rem] flex items-center justify-center',
          withBorder && 'border-2 border-solid border-hawkes-blue rounded-lg',
        )}
      >
        {image.content ? (
          <GatsbyImage image={getImage(image.content)!} className={imageOutputClass} alt={name} />
        ) : (
          <img src={image.src} alt={name} className={imageOutputClass} />
        )}
      </OuterLink>
    </div>
  );
};
