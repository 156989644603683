import { useMemo } from 'react';

import { useGetPartnersImages } from '../useGetPartnersImages';

export interface PartnerImageConfig {
  name: string;
  url: string;
  image: {
    name: string;
    className?: string;
  };
}

export function usePartnerImages(images: PartnerImageConfig[]) {
  const imagesOptimized = useGetPartnersImages();

  return useMemo(() => {
    return images.map((partner: any) => ({
      ...partner,
      image: { ...imagesOptimized.find((image: any) => partner.image.name === image.name), ...partner.image },
    }));
  }, [images]);
}
