import pharmacy911 from '@assets/images/partners/pharmacy-911.svg';
import shopSilpoPartnerLogo from '@assets/images/partners/shop-silpo.svg';
import shopTrashPartnerLogo from '@assets/images/partners/shop-trash.svg';
import shopForaPartnerLogo from '@assets/images/partners/shop-fora.svg';
import socarPartnerLogo from '@assets/images/partners/socar.svg';
import blagodiaPartnerLogo from '@assets/images/partners/blagodia.svg';
import kopiikaPartnerLogo from '@assets/images/partners/kopiika.svg';
import sharaPartnerLogo from '@assets/images/partners/shara.svg';

export default [
  {
    name: 'Онлайн супермаркет «Сільпо»',
    url: 'https://silpo.ua/',
    image: {
      name: 'shop-silpo',
      src: shopSilpoPartnerLogo,
      className: 'w-[120px]',
    },
  },
  {
    name: 'LeSilpo',
    url: 'https://www.instagram.com/lesilpo/',
    image: {
      name: 'shop-le-silpo',
      className: 'w-[120px]',
    },
  },
  {
    name: 'Мережа магазинів "Фора"',
    url: 'https://fora.ua/',
    image: {
      name: 'shop-fora',
      src: shopForaPartnerLogo,
      className: 'w-[120px]',
    },
  },
  {
    name: 'Мережа магазинів "Trash"',
    url: 'https://thrash.ua/',
    image: {
      name: 'shop-trash',
      src: shopTrashPartnerLogo,
      className: 'w-[120px]',
    },
  },
  {
    name: 'Аптека 3i',
    url: 'https://3i.ua/',
    image: {
      name: 'pharmacy-3і',
      className: 'w-[199px] h-[30px]',
    },
  },
  {
    name: 'Аптека 9-1-1',
    url: 'https://apteka911.ua/',
    image: {
      name: 'pharmacy-911',
      src: pharmacy911,
      className: 'w-[68px] h-[68px]',
    },
  },
  {
    name: 'WOG',
    url: 'https://wog.ua/',
    image: {
      name: 'wog',
      className: 'w-[120px]',
    },
  },
  {
    name: 'АЗК "Окко"',
    url: 'https://www.okko.ua/',
    image: {
      name: 'okko',
      className: 'w-[50px]',
    },
  },
  {
    name: 'Будинок іграшок',
    url: 'https://bi.ua/',
    image: {
      name: 'home-of-toys',
      className: 'w-[120px]',
    },
  },
  {
    name: 'Chicco',
    url: 'https://chicco.com.ua/ua',
    image: {
      name: 'chicco',
      className: 'w-[120px]',
    },
  },
  {
    name: 'VIP MALUK - Дитячі товари',
    url: 'https://vipmaluk.com.ua/ua/',
    image: {
      name: 'vip-maluk',
      className: 'w-[80px]',
    },
  },
  {
    name: 'Інтернет-магазин Babyshop - Все для мами та дитини',
    url: 'https://babyshop.ua/ua',
    image: {
      name: 'babyshop',
      className: 'w-[120px]',
    },
  },
  {
    name: 'ATB',
    url: 'https://www.atbmarket.com/',
    image: {
      name: 'atb',
      className: 'w-[120px]',
    },
  },
  {
    name: 'Socar',
    url: 'https://socar.ua/',
    image: {
      src: socarPartnerLogo,
      className: 'w-[80px]',
    },
  },
  {
    name: 'Аптека АНЦ',
    url: 'https://anc.ua/',
    image: {
      name: 'anc',
      className: 'w-[150px]',
    },
  },
  {
    name: 'Аптека Благодія',
    url: 'https://anc.ua/',
    image: {
      src: blagodiaPartnerLogo,
      className: 'w-[150px]',
    },
  },
  {
    name: 'Аптека Koпійка',
    url: 'https://anc.ua/',
    image: {
      src: kopiikaPartnerLogo,
      className: 'w-[150px]',
    },
  },
  {
    name: 'Аптека Шар@',
    url: 'https://anc.ua/',
    image: {
      src: sharaPartnerLogo,
      className: 'w-[150px]',
    },
  },
];
